/* PDF VIEWER */

.pdfViewerParent {
    align-self: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pdfViewerWrapper {
    max-width: 100%;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.1);
    border-radius: 5px 5px 0px 0px;
    overflow: hidden;
}

.pdfViewerWrapper > .pdfViewer {
    overflow: hidden;
    max-width: 100%;
}

.pdfViewerWrapper > .pdfViewer > .react-pdf__Page { 
    max-width: inherit;
}

.pdfViewerParent > .pdfNavigator {
    background: #00A3CA;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.1);
    padding: 0px;
    border-radius: 0px 0px 5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 2px;
    width: 100%;
    position: relative;
}

.pdfViewerParent > .pdfNavigator > p {
    font-size: 12px;
    font-weight: 700;
    color: #fff;
}

.pdfViewerParent > .pdfNavigator > button,
.pdfViewerParent > .pdfNavigator > button > .MuiTouchRipple-root {
    border-radius: 0px;
    color: #fff;
}

.pdfViewerParent > .pdfNavigator > button.Mui-disabled {
    color: rgba(255, 255, 255, 0.3);
}